import Vue from "vue";
import VueRouter from "vue-router";
import Tags from "../views/Tags.vue";
import Items from "../views/Items.vue";
import CSVUpload from "../views/CSVUpload.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Associate from "../views/Associate.vue";
import ImageUpload from "../views/ImageUpload.vue";
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Tags",
    component: Tags,
    beforeEnter: authGuard,
    meta: {
      title: "Tags"
    }
  },
  {
    path: "/items",
    name: "items",
    component: Items,
    beforeEnter: authGuard,
    meta: {
      title: "Items"
    }
  },
  {
    path: "/csv",
    name: "CSVUpload",
    component: CSVUpload,
    beforeEnter: authGuard,
    meta: {
      title: "CSV Upload"
    }
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: {
      title: "Verify Email"
    }
  },
  {
    path: "/associate",
    name: "Associate",
    component: Associate,
    beforeEnter: authGuard,
    meta: {
      title: "Associate"
    }
  },
  {
    path: "/image",
    name: "ImageUpload",
    component: ImageUpload,
    beforeEnter: authGuard,
    meta: {
      title: "Image Upload"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = `${nearestWithTitle.meta.title} | V:Cloud UI`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
