import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExchangeAlt, faBolt, faSearch} from "@fortawesome/free-solid-svg-icons";

library.add(faExchangeAlt, faBolt, faSearch);

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";
Vue.component("font-awesome-icon", FontAwesomeIcon);
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("v-select", vSelect);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
// import "nprogress/nprogress.css";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
