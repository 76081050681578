<template lang="pug">
  .item-preview
    pre {{item}}
</template>

<script>
export default {
  name: "item-preview",
  props: {
    item: {
      type: Object,
      required: true
    },
  },
};
</script>
