<template lang="pug">
  .tags.b-container(v-if="getToken")
    h1.p-2 Items
    b-row
      b-col(cols="5")
        b-select(:options="storeOptions" v-model="storeId" required)
      b-col(cols="3")
        b-form(v-if="items" inline @submit.stop.prevent)
          b-form-group(label="Search: " label-for="filterSearch")
            b-input#filterSearch(v-model="filterSearch" debounce="500")
    b-row.p-3.chart-row.justify-content-center
      b-table.m-2(v-if="items" :items="searchResults" :fields="fields")
        template(v-slot:cell(actions)="data") 
          b-button(variant="primary" :to="`/associate?store_id=${storeId}&item_id=${data.item.itemId}`") Associate
            font-awesome-icon.ml-1(icon="exchange-alt")
          b-button.preview-btn.ml-2(v-b-tooltip.hover title="Preview" @click="preview(data.item)" variant="success") View 
            font-awesome-icon(icon="search")
    b-modal#preview(ok-only :title="previewItem ? previewItem.name : ''")
      ItemPreview(:item="previewItem")
</template>

<script>
import VLinkPro from "@/services/VLinkPro";
import { mapGetters } from "vuex";
import ItemPreview from "../components/ItemPreview";

const vLinkAPI = new VLinkPro();

export default {
  name: "Tags",
  components: {
    ItemPreview
  },
  data() {
    return {
      fields: ["itemId", "name", "status", "price", "actions"],
      storeId: null,
      items: null,
      searchTerm: "",
      previewItem: null,
      filterSearch: ""
    };
  },
  methods: {
    preview(toPreview) {
      this.previewItem = toPreview;
      this.$bvModal.show("preview");
    }
  },
  computed: {
    storeOptions() {
      let options = [
        { value: null, text: "Please select a store", disabled: true }
      ];
      for (const storeId in this.stores) {
        options.push({ value: storeId, text: this.stores[storeId] });
      }
      return options;
    },
    store() {
      return this.stores.find(store => store.storeId == this.storeId);
    },
    searchResults() {
      const toFilter = this.items.filter(
        item =>
          JSON.stringify(Object.values(item))
            .toLowerCase()
            .indexOf(this.filterSearch.toLowerCase()) > -1
      );
      return toFilter;
    },
    ...mapGetters(["getToken", "stores"])
  },
  created() {},
  watch: {
    storeId() {
      vLinkAPI.getItems(this.storeId).then(items => (this.items = items.data));
    }
  }
};
</script>

<style lang="scss">
.preview-btn {
  width: inherit !important;
}
</style>
