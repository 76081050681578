<template lang="pug">
  .home
    div(v-if="!$auth.loading")
      div.d-flex.flex-row.justify-content-center.flex-wrap
        h2 Verify your e-mail to finish signing up
        button(@click="logout") Return to Login
</template>

<script>
export default {
  name: "VerifyEmail",
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

<style lang="scss">
h2 {
  width: 100%;
  text-align: center;
}
button {
  width: 175px;
}
</style>
