<template lang="pug">
  b-navbar.mb-2(toggleable="lg" type="dark" variant="dark")
    b-navbar-brand(to="/") V:Cloud UI
    b-navbar-toggle(target="nav-collapse")
    b-collapse#nav-collapse(is-nav)
      template(v-if="!$auth.loading")
        b-navbar-nav()
          
          template(v-if="$auth.isAuthenticated")
            b-nav-item(to="/") Tags
            b-nav-item(to="/items") Items
            b-nav-item(to="/associate") Associate
            b-nav-item(to="/image") Image Upload
            //- b-nav-item(to="/csv") CSV Upload

        b-navbar-nav.ml-auto
          template(v-if="$auth.isAuthenticated")
            b-navbar-brand
            img.d-inline-block.align-top(:src="gravatarHash")
            b-nav-item {{$auth.user.name}}
            b-nav-item-dropdown(text="Settings" right)
              b-dropdown-item(disabled) Client: v{{clientVersion}}
            b-navbar-nav
              b-nav-item(@click="logout") Logout
          template(v-else)
            b-navbar-nav
              b-nav-item(@click="login") Login

</template>

<script>
import { mapGetters } from "vuex";
import md5 from "md5";
import { SET_AUTH0_TOKEN, GET_PROFILE } from "@/store/actions/user";

export default {
  name: "navigation",
  data() {
    return {
      clientVersion: process.env.PACKAGE_VERSION
    };
  },
  computed: {
    gravatarHash() {
      if (this.$auth.isAuthenticated) {
        return `https://www.gravatar.com/avatar/${md5(
          this.$auth.user.email.toLowerCase()
        )}?s=40&d=mp`;
      }
      return "";
    },
    ...mapGetters([])
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    async login() {
      await this.$auth.loginWithPopup();
    }
  },
  created() {},
  watch: {
    "$auth.isAuthenticated"(newVal) {
      if (newVal) {
        this.$auth
          .getTokenSilently()
          .then(token => this.$store.commit(SET_AUTH0_TOKEN, token))
          .then(() => this.$store.dispatch(GET_PROFILE));
      }
    }
  }
};
</script>
