<template lang="pug">
.container.b-container-xl(v-if="getToken")
  h1.p-2 Image Upload
  b-row.d-flex.flex-row
    b-col.p-5.d-flex.flex-column.justify-content-start(cols="5")
      b-form-group(label="Select a store")
        b-select.m-2(:options="storeOptions" v-model="storeId" required)
      b-form-group.tag-options-group(label="Label Options")
        b-form-input.m-2(
          type="text"
          placeholder="Label ID"
          ref="labelID"
          v-model="labelID"
          @blur="getLabelDetails"
          :state="tagIdValidation"
          :disabled="!storeId"
        )
        b-form-invalid-feedback(v-if="fetchedLabelDetails" :state="tagIdValidation") Invalid Label ID
        b-form-valid-feedback(v-if="fetchedLabelDetails" :state="tagIdValidation") Valid Label ID
        b-form-input.m-2(
          type="text"
          placeholder="NFC Url"
          ref="nfc"
          v-model="nfcUrl"
          :disabled="!storeId"
        )
      b-form-group(label="Upload Type")
        b-select.m-2(
          :options="uploadOptions"
          v-model="uploadType"
          required
          :disabled="!labelDetails",
        )
        b-form-file.m-2(
          v-if="uploadType === 'image'"
          @change="updateCanvas"
          size="sm"
          :disabled="!labelDetails"
        )
        b-form-input.m-2(
          v-if="uploadType === 'base64'"
          type="text"
          placeholder="Base64"
          ref="base64Field"
          v-model="base64Field"
          @blur="updateCanvasByBase64"
          :disabled="!labelDetails"
        )
      b-button.m-2.upload-button(
        :disabled="!uploadType || !labelDetails"
        variant="success"
        @click="upload"
      ) Upload
    b-col.p-5(cols="7")
      b-row.d-flex.flex-row.justify-content-around.align-items-center
        span Tag Type:
          span(v-if="labelDetails") {{ labelDetails.typeName }}
        span Tag Width:
          span(v-if="labelDetails") {{ labelDetails.width }}
        span Tag Height:
          span(v-if="labelDetails") {{ labelDetails.height }}
        span
          b-button.m-2.upload-button(
          :disabled="!labelDetails",
          variant="primary"
          @click="rotateCanvas"
        ) Rotate
      b-row.p-3
        canvas(ref="imageCanvas")
  div {{ correlation }}
</template>

<script>
import { IMAGE_REQUEST } from "@/store/actions/image";
import { MESSAGE_ADD } from "@/store/actions/messages";
import { mapGetters } from "vuex";
import mediaTypes from "../mediaTypes.json";
import VLinkPro from "@/services/VLinkPro";

const vLinkAPI = new VLinkPro();

export default {
  name: "ImageUpload",
  components: {},
  data() {
    return {
      storeId: null,
      labelID: null,
      fetchedLabelDetails: null,
      labelDetails: null,
      nfcUrl: null,
      base64: null,
      base64Field: null,
      uploadType: null,
      imageSelected: false,
      correlation: null,
      image: null,
      mediaTypes,
      isRotated: false,
      uploadOptions: [
        { value: null, text: "Please select an upload type", disabled: true },
        { value: "image", text: "Image", disabled: false },
        { value: "base64", text: "Base64 String", disabled: false },
      ],
    };
  },
  methods: {
    updateCanvas(e) {
      const self = this;
      let files = e.target.files;
      let reader = new FileReader();

      reader.onload = () => {
        let img = new Image();
        img.onload = function() {
          self.drawCanvasImage(img);
        };
        img.src = event.target.result;
      };

      reader.readAsDataURL(files[0]);
    },
    updateCanvasByBase64() {
      let img = new Image();
      const self = this;
      img.onload = function() {
        self.drawCanvasImage(img);
      };
      img.src = this.base64Field;
    },
    drawCanvasImage(img) {
      this.image = img;
      const canvas = this.$refs.imageCanvas;

      if (
        this.labelDetails.typeName == "SmartESL default" ||
        this.labelDetails.denomination == "SmartESL default"
      ) {
        canvas.width = img.width;
        canvas.height = img.height;
      } else {
        if (this.labelDetails.defaultOrientation == "PORTRAIT") {
          canvas.width = this.labelDetails.height;
          canvas.height = this.labelDetails.width;
        } else {
          canvas.width = this.labelDetails.width;
          canvas.height = this.labelDetails.height;
        }
      }

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      this.imageSelected = true;
    },
    rotateCanvas() {
      const image = this.image;
      const canvas = this.$refs.imageCanvas;
      const ctx = canvas.getContext("2d");
      const newWidth = canvas.height;
      const newHeight = canvas.width;

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();

      if (!this.isRotated) {
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((90 * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        ctx.restore();
        this.isRotated = true;
      } else {
        ctx.drawImage(image, 0, 0);
        ctx.restore();
        this.isRotated = false;
      }
    },
    upload() {
      if (!this.labelDetails) {
        this.$store.dispatch(MESSAGE_ADD, {
          title: "Image Error",
          body: `A Label type was not found for the ID ${this.labelID}. Please check Label ID.`,
          category: "warning",
        });
        return;
      }
      if (this.labelDetails.denomination == "SmartESL default") {
        this.$store.dispatch(MESSAGE_ADD, {
          title: "Image Error",
          body: `Label ${this.labelID} could not be found in the system. Image may not fit default label dimensions.`,
          category: "warning",
        });
      } else {
        if (
          this.image.width != this.labelDetails.width ||
          this.image.height != this.labelDetails.height
        ) {
          this.$store.dispatch(MESSAGE_ADD, {
            title: "Image Error",
            body: `The Image provided does not fit Tag ${this.labelID}'s Dimensions. It will be cropped or include whitespace.`,
            category: "warning",
          });
        }
      }

      const imageData = this.$refs.imageCanvas.toDataURL(`image/png`);
      this.base64 = this.base64Field
        ? this.base64Field.replace(`data:image/png;base64,`, ``)
        : imageData.replace(`data:image/png;base64,`, ``);

      let data = [
        {
          labelId: this.labelID,
          nfc: this.nfcUrl,
          pages: [
            {
              page: 0,
              preload: false,
              image: this.base64,
            },
          ],
        },
      ];
      this.$store
        .dispatch(IMAGE_REQUEST, {
          storeID: this.storeId,
          data: data,
        })
        .then((resp) => console.log(resp));
    },
    getLabelDetails() {
      vLinkAPI.getLabel(this.storeId, this.labelID).then(item => {
        if (item.data.hardware) {
          this.labelDetails = item.data.hardware;
        } else {
          let foundType = mediaTypes.find(type => {
            const re = new RegExp(type.pattern);
            if (this.labelID.match(re)) {
              return type;
            }
          });
          this.labelDetails = foundType ? foundType : null;
        }
        this.fetchedLabelDetails = true;
      });
    }
  },
  computed: {
    storeOptions() {
      let options = [
        { value: null, text: "Please select a store", disabled: true },
      ];
      for (const storeId in this.stores) {
        options.push({ value: storeId, text: this.stores[storeId] });
      }
      return options;
    },
    tagIdValidation() {
      if (this.fetchedLabelDetails) {
        return this.labelDetails ? true : false;
      } else {
        return null;
      }
    },
    ...mapGetters(["getToken", "stores"]),
  },
  created() {
    this.storeId = this.$route.query.store_id || null;
  },
};
</script>

<style lang="scss">
#app {
  .upload-button {
    width: 80px;
  }

  .valid-feedback,
  .invalid-feedback {
    padding-left: 1rem;
  }

  canvas {
    border: 1px dotted #000;
  }
}
</style>
