<template lang="pug">
  .tags.b-container(v-if="getToken")
    h1.p-2 Tags
    b-row
      b-col(cols="5")
        b-select(:options="storeOptions" v-model="storeId" required)
      b-col(cols="3")
        b-form(v-if="labels" inline @submit.stop.prevent)
          b-form-group(label="Search: " label-for="filterSearch")
            b-input#filterSearch(v-model="filterSearch" debounce="500")
    b-row.p-3.chart-row.justify-content-center
      b-table.m-2(v-if="labels" :items="searchResults" :fields="fields")
        template(v-slot:cell(status)="data") {{data.item.connectivity.status}}
        template(v-slot:cell(batteryStatus)="data") {{data.item.hardware.battery}}
        template(v-slot:cell(associatedItem)="data") 
          template(v-if="data.item.matching.items[0]")  {{data.item.matching.items[0].name || "None"}}
        template(v-slot:cell(actions)="data") 
          b-button(variant="primary" :to="`/associate?store_id=${storeId}&label_id=${data.item.labelId}`") Associate
            font-awesome-icon.ml-1(icon="exchange-alt")
          b-button.ml-3.flash-btn(variant="success" @click="flash(data.item.labelId)") Flash
            font-awesome-icon.ml-1(icon="bolt")
</template>

<script>
import VLinkPro from "@/services/VLinkPro";
import { mapGetters } from "vuex";

const vLinkAPI = new VLinkPro();

export default {
  name: "Tags",
  data() {
    return {
      fields: [
        "labelId",
        "status",
        "batteryStatus",
        "associatedItem",
        "actions"
      ],
      storeId: null,
      labels: null,
      filterSearch: "",
    };
  },
  methods: {
    flash(labelId) {
      vLinkAPI.flashLabel(this.storeId, [
        {
          labelId: labelId,
          color: "WHITE",
          duration: 10,
          pattern: "EACH_2_SECONDS"
        }
      ]);
    }
  },
  computed: {
    storeOptions() {
      let options = [
        { value: null, text: "Please select a store", disabled: true }
      ];
      for (const storeId in this.stores) {
        options.push({ value: storeId, text: this.stores[storeId] });
      }
      return options;
    },
    searchResults() {
      const toFilter = this.labels
        .filter(
          label =>
            JSON.stringify(Object.values(label))
              .toLowerCase()
              .indexOf(this.filterSearch.toLowerCase()) > -1
        )
      return toFilter;
    },
    ...mapGetters(["getToken", "stores"])
  },
  watch: {
    storeId() {
      vLinkAPI
        .getLabels(this.storeId)
        .then(labels => (this.labels = labels.data));
    }
  }
};
</script>

<style lang="scss">
.flash-btn {
  width: inherit;
}
</style>
