import Vue from "vue";
import Vuex from "vuex";
import MessagesModule from "./modules/messages";
import UserModule from "./modules/user";
import AssociateModule from "./modules/associate";
import ImageModule from "./modules/image";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    MessagesModule,
    UserModule,
    AssociateModule,
    ImageModule
  }
});
