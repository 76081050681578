<template lang="pug">
  #app
    Navigation
    Messages
    .container-fluid.p-5
      router-view
</template>

<script>
import Navigation from "@/components/Navigation";
import Messages from "@/components/Messages.vue";
// import nprogress from "@/components/nprogress";

export default {
  components: {
    Navigation,
    Messages
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
