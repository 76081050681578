import axios from "axios";

export default class VLinkPro {
  BASE_URL = "/api/vcloud/";

  constructor() {}

  getLabels(storeId) {
    return this.get(`labels/${storeId}`);
  }

  getLabel(storeId, labelId) {
    return this.get(`label/${labelId}/${storeId}`);
  }

  getItems(storeId) {
    return this.get(`items/${storeId}`);
  }

  saveItems(items) {
    return this.post("items", items);
  }

  getScenarios(storeId) {
    return this.get(`scenarios/${storeId}`);
  }

  associate(storeId, association) {
    return this.post(`associate/${storeId}`, association);
  }

  pushImage(storeId, image) {
    return this.post(`pushImage/${storeId}`, image);
  }

  get(endpoint, params) {
    return axios.get(this.BASE_URL + endpoint, {
      params
    });
  }

  post(endpoint, data, headers) {
    return axios.post(this.BASE_URL + endpoint, data, {
      headers
    });
  }

  flashLabel(storeId, labelId) {
    return this.post(`flash/${storeId}`, labelId);
  }

  uploadFile(storeId, file) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post(`${this.BASE_URL}upload/${storeId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
}
