import { SET_AUTH0_TOKEN, GET_PROFILE, SET_PROFILE } from "../actions/user";
import axios from "axios";

const AuthModule = {
  state: {
    token: null,
    profile: null
  },
  getters: {
    getToken: state => state.token,
    stores: state => (state.profile ? state.profile.stores : {})
  },
  actions: {
    [GET_PROFILE]: ({ commit }) =>
      new Promise((resolve, reject) => {
        axios
          .get("/api/me")
          .then(resp => {
            const { data } = resp;
            commit(SET_PROFILE, data);
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      })
  },
  mutations: {
    [SET_AUTH0_TOKEN]: (state, token) => {
      state.token = token;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    [SET_PROFILE]: (state, profile) => {
      state.profile = profile;
    }
  }
};
export default AuthModule;
