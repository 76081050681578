import { IMAGE_REQUEST } from "@/store/actions/image";
import { MESSAGE_ADD } from "../actions/messages";
import VLinkPro from "@/services/VLinkPro";
const vLinkAPI = new VLinkPro();

const ImageModule = {
  state: {},
  geters: {},
  actions: {
    [IMAGE_REQUEST]: ({ dispatch }, data) =>
      new Promise((resolve, reject) => {
        vLinkAPI
          .pushImage(data.storeID, data.data)
          .then(resp => {
            dispatch(MESSAGE_ADD, {
              title: "Push Image",
              body: `Successfully Pushed Image to ${data.data[0].labelId}.`,
              category: "success"
            });
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      })
  },
  mutations: {}
};
export default ImageModule;
