<template lang="pug">
  b-row
    b-col(cols="12" md="8" lg="8")
      h1 Associate Tags
      b-form(@submit.prevent="associate" v-if="getToken")
        b-form-group(label="Select a store")
          b-select(:options="storeOptions" v-model="storeId" required)
        template(v-if="storeId")
          b-form-group(label="Scan or type in label ID (barcode)")
            b-input(v-model="labelId" :formatter="formatter" placeholder="A100000" required)
          template(v-if="labelId && scenarios")
            b-form-group(label="Select a scenario")
              b-select(:options="scenarioOptions" v-model="scenarioId" required)
          template(v-if="items && items.length > 0")
            b-form-group(label="Select an Item")
              v-select(v-model="itemId" :filter="fuseSearch" :options="items" label="name" @input="addItem")
                template(v-slot:option="item")
                  div {{item.name}}
                  cite SKU: {{ item.id }}
                template(#search="{attributes, events}")
                  input(class="vs__search" :required="itemsToAssociate == 0 && itemId == null" v-bind="attributes" v-on="events")
          template(v-if="items && items.length > 0 && scenarioId")
            p Items to associate to {{labelId}}
            ol
             li(v-for="n in parseInt(activeScenario.itemCount)")
                template(v-if="itemsToAssociate[n-1]")
                  b-row
                    b-col(cols="10") {{itemsToAssociate[n-1].name}}
                    b-col(cols="2")
                      b-button(size="sm" variant="danger" @click="deleteItem(n-1)") Delete
          b-button(type="submit" variant="primary") Associate
</template>

<script>
import Fuse from "fuse.js";
import VLinkPro from "@/services/VLinkPro";
import { ASSOCIATE_REQUEST } from "@/store/actions/associate";
import { MESSAGE_ADD } from "@/store/actions/messages";
import { mapGetters } from "vuex";

const vLinkAPI = new VLinkPro();

export default {
  name: "Associate",
  data() {
    return {
      storeId: null,
      items: null,
      labelId: "",
      itemId: null,
      itemsToAssociate: [],
      scenarios: null,
      scenarioId: null,
      activeScenario: null,
      itemCount: null,
      searchTerm: ""
    };
  },
  methods: {
    addItem(item) {
      this.itemsToAssociate.push(item);
      this.itemId = null;
    },
    deleteItem(itemIndex) {
      this.itemsToAssociate.splice(itemIndex, 1);
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["name" /*"classification.family", "custom.strainType" */],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    associate() {
      if (
        this.itemsToAssociate.length < parseInt(this.activeScenario.itemCount)
      ) {
        this.$store.dispatch(MESSAGE_ADD, {
          title: "Form error",
          body: `The scenario chosen requires ${this.activeScenario.itemCount} item(s)`,
          category: "warning"
        });
        return;
      }

      this.$store
        .dispatch(ASSOCIATE_REQUEST, {
          storeId: this.storeId,
          data: [
            {
              labelId: this.labelId,
              scenarioId: this.activeScenario.scenarioId,
              items: this.itemsToAssociate
            }
          ]
        })
        .then(resp => console.log(resp));
    },
    formatter(value) {
      return value.toUpperCase();
    }
  },
  computed: {
    storeOptions() {
      let options = [
        { value: null, text: "Please select a store", disabled: true }
      ];
      for (const storeId in this.stores) {
        options.push({ value: storeId, text: this.stores[storeId] });
      }
      return options;
    },
    store() {
      return this.stores.find(store => store == this.storeId);
    },
    scenarioOptions() {
      let options = [
        { value: null, text: "Please select a template", disabled: true }
      ];
      this.scenarios.forEach(scenario => {
        const re = new RegExp(scenario.pattern);
        if (this.labelId.match(re)) {
          scenario.scenarios.forEach(specificScenario => {
            const itemCount = parseInt(specificScenario.itemCount);
            options.push({
              text: `${specificScenario.scenarioName} (${itemCount} item${
                itemCount > 1 ? "s" : ""
              })`,
              value: `${specificScenario.scenarioId}||${specificScenario.itemCount}`
            });
          });
        }
      });
      return options;
    },
    ...mapGetters(["getToken", "stores"])
  },
  created() {
    this.storeId = this.$route.query.store_id || null;
    this.labelId = this.$route.query.label_id || null;
    this.itemId = this.$route.query.item_id || null;
  },
  watch: {
    storeId() {
      vLinkAPI.getItems(this.storeId).then(items => (this.items = items.data));
      vLinkAPI
        .getScenarios(this.storeId)
        .then(scenarios => (this.scenarios = scenarios.data));
    },
    scenarioId() {
      let activeScenario = null;
      const scenarioId = this.scenarioId.split("||")[0];
      const itemCount = this.scenarioId.split("||")[1];
      this.scenarios.forEach(scenario => {
        scenario.scenarios.forEach(specificScenario => {
          if (
            specificScenario.scenarioId == scenarioId &&
            specificScenario.itemCount == itemCount
          )
            activeScenario = specificScenario;
        });
      });
      this.activeScenario = activeScenario;
    }
  }
};
</script>
