<template lang="pug">
  b-row
    b-col(cols="5")
      h1 CSV File Upload
      b-form(@submit.prevent="uploadCSV" enctype="multipart/form-data")
        b-form-group
          b-select(:options="storeOptions" v-model="storeId" required)
        b-form-group
          b-form-file(v-model="file" :state="Boolean(file)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept=".csv,.tsv,.txt,.xml")
            .mt-3 Selected file: {{ file ? file.name : '' }}
        b-button(type="submit" variant="primary") Go
</template>

<script>
import { mapGetters } from "vuex";
import { MESSAGE_ADD } from "@/store/actions/messages";
import VLinkPro from "@/services/VLinkPro";

const vLinkAPI = new VLinkPro();

export default {
  name: "CSVUpload",
  data() {
    return {
      file: null,
      storeId: null
    };
  },
  methods: {
    uploadCSV() {
      vLinkAPI.uploadFile(this.storeId, this.file).then(() => {
        this.$store.dispatch(MESSAGE_ADD, {
          title: "File Upload Success",
          body: `The file has been successfully uploaded.`,
          category: "success"
        });
      });
    }
  },
  computed: {
    storeOptions() {
      let options = [
        { value: null, text: "Please select a store", disabled: true }
      ];
      for (const storeId in this.stores) {
        options.push({ value: storeId, text: this.stores[storeId] });
      }
      return options;
    },
    ...mapGetters(["getToken", "stores"])
  }
};
</script>
